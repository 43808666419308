
  import { Component, Prop, Vue } from "vue-property-decorator";
  import type { PropertyInfoBlock } from "@/mixins/properties/has-config";

  @Component
  export default class PagePropertyPartGeneralInfoBlock extends Vue {
    @Prop({ required: true }) tab!: PropertyInfoBlock;
    @Prop({ required: true }) activityId!: number;
    @Prop({ default: false }) canEdit!: boolean;
  }
